import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";

import Marker from "../../atoms/marker/Marker";

const GoogleMaps = ({ latitude, longitude, height = "250px" }) => {
  return (
    <div style={{ height: height, width: "100%" }}>
      <GoogleMapReact
        // TODO: get this from env variable
        bootstrapURLKeys={{ key: "AIzaSyDIpbQ_nXmuKztLCylefoCQjxI-ayXbjSQ" }} 
        defaultZoom={2}
        center={[latitude, longitude]}
        defaultCenter={[19.07, 72.87]}
      >
        {latitude && <Marker lat={latitude} lng={longitude} />}
      </GoogleMapReact>
    </div>
  );
};

GoogleMaps.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
};

GoogleMaps.defaultProps = {
  latitude: 40.756795,
  longitude: -73.954298,
};

export default GoogleMaps;
